import { action } from 'easy-peasy'

const authStore = {
  user: null,
  credentials: null,
  setAuth: action((state, payload) => {
    const { user, credentials } = payload

    if (user) state.user = user
    if (credentials) state.credentials = credentials
  }),
  unsetAuth: action((state, _payload) => {
    state.user = null
    state.credentials = null
  })
}

export default authStore
