import { action } from 'easy-peasy'

const countryStore = {
  currency: 'MYR',
  countryChecked: false,
  setCurrency: action((state, payload) => {
    const { currency } = payload

    if (currency) state.currency = currency
  }),
  setCountryChecked: action((state, payload) => {
    const { countryChecked } = payload

    if (countryChecked) state.countryChecked = countryChecked
  })
}

export default countryStore
