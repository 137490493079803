import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react'

const theme = extendTheme(
  {
    styles: {
      global: (props) => ({
        body: {
          color: 'white',
          background: '#1d1d1d'
        }
      })
    },
    shadows: {
      outline: 0
    },
    components: {
      Modal: {
        baseStyle: (props) => ({
          dialog: {
            background: '#1d1d1d',
            marginX: 4
          },
          header: {
            fontSize: '3xl',
            fontWeight: 700
          }
        })
      },
      Popover: {
        baseStyle: (props) => ({
          content: {
            background: '#1d1d1d',
            borderColor: 'whiteAlpha.200'
          }
        })
      },
      Input: {
        variants: {
          outline: (props) => ({
            field: {
              borderWidth: '2px',
              borderColor: 'gray.400',
              _focus: {
                boxShadow: 'none'
              },
              _invalid: {
                borderColor: 'red.400',
                boxShadow: 'none'
              }
            }
          })
        }
      },
      FormError: {
        baseStyle: (props) => ({
          text: {
            lineHeight: 'normal',
            color: 'red.400'
          }
        })
      },
      Alert: {
        variants: {
          toast: (props) => ({
            container: {
              // boxShadow: '!important 0px 2px 6px 0px rgba(0,0,0,0.12)',
              bg: 'gray.800',
              fontSize: 'sm',
              fontWeight: 500
            },
            icon: {
              color: `${
                props.status === 'success'
                  ? 'green'
                  : props.status === 'info'
                  ? 'blue'
                  : props.status === 'warning'
                  ? 'orange'
                  : props.status === 'error'
                  ? 'red'
                  : 'gray'
              }.400`
            }
          })
        }
      }
    }
  },
  withDefaultColorScheme({ colorScheme: 'blue' })
)

export default theme
