import { Center, Spinner } from '@chakra-ui/react'
import { useStoreRehydrated, useStoreState } from 'easy-peasy'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'

const LoadingView = () => {
  return (
    <Center width="full" height="100vh">
      <Spinner size="lg" thickness="4px" color="blue.500" />
    </Center>
  )
}

const RouteGuard = ({ children }) => {
  const router = useRouter()
  const auth = useStoreState((state) => state.auth)
  const rehydrated = useStoreRehydrated()
  const isBrowser = typeof window !== 'undefined'
  const isAuthenticated = !!auth?.user && !!auth?.credentials

  if (!rehydrated) {
    return <LoadingView />
  }

  if (isBrowser) {
    if (
      isAuthenticated &&
      (router.asPath === '/login' ||
        router.asPath === '/register' ||
        router.asPath === '/reset')
    ) {
      router.replace('/')

      return <LoadingView />
    }

    if (
      !isAuthenticated &&
      router.asPath.startsWith('/logout')
      // (router.asPath.startsWith('/logout') ||
      //   router.asPath.startsWith('/payment'))
    ) {
      router.replace('/')

      return <LoadingView />
    }

    return children
  } else {
    return children
  }
}

RouteGuard.propTypes = {
  children: PropTypes.node.isRequired
}

export default RouteGuard
